.PanelHeader {
  position: relative;
}

.PanelHeader--vis.PanelHeader--fixed::before {
  display: block;
  content: '';
}

.PanelHeader:not(.PanelHeader--vis):not(.PanelHeader--fixed) {
  height: 0;
}

.PanelHeader__fixed {
  z-index: var(--vkui_internal--z_index_panel_header_fixed);
}

.PanelHeader__in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: relative;
  background: var(--vkui--color_background_content);
}

.PanelHeader--trnsp .PanelHeader__in {
  background: transparent;
}

.PanelHeader--sizeX-regular .PanelHeader--shadow .PanelHeader__in {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
}

@media (--sizeX-regular) {
  .PanelHeader--sizeX-none .PanelHeader--shadow .PanelHeader__in {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
  }
}

.PanelHeader__before {
  box-sizing: border-box;
  color: var(--vkui--color_icon_accent_themed);
  display: flex;
  flex-shrink: 0;
}

.PanelHeader__content {
  overflow: hidden;
}

.PanelHeader__content > * {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PanelHeader__content-in {
  color: var(--vkui--color_text_primary);
  font-weight: 500;
  font-family: var(--vkui--font_family_accent);
}

.PanelHeader::before,
.PanelHeader__in {
  height: var(--vkui--size_panel_header_height--regular);
  padding-top: var(--vkui_internal--safe_area_inset_top);
}

.PanelHeader__after {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  color: var(--vkui--color_icon_accent_themed);
}

.PanelHeader--vkapps .PanelHeader__after {
  min-width: 90px;
}

/**
 * iOS
 */
.PanelHeader--ios .PanelHeader__before {
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
  opacity: 1;
  transition: opacity 0.3s var(--vkui--animation_easing_platform);
  padding: 4px 0 4px 4px;
}

.PanelHeader--ios .PanelHeader__before .PanelHeaderButton + .PanelHeaderButton--primitive {
  margin-left: -6px;
  padding-left: 0;
}

.PanelHeader--ios .PanelHeader__content {
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s var(--vkui--animation_easing_platform);
}

.PanelHeader--ios .PanelHeader__content-in {
  font-size: 21px;
}

.PanelHeader--ios .PanelHeader__content > * {
  padding: 0 4px;
}

.PanelHeader--ios.PanelHeader--no-before .PanelHeader__content {
  padding-left: 8px;
}

.PanelHeader--ios.PanelHeader--no-after .PanelHeader__content > * {
  padding-right: 0;
}

.PanelHeader--ios.PanelHeader--no-after .PanelHeader__content {
  padding-right: 8px;
}

.PanelHeader--ios .PanelHeader__after {
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
  opacity: 1;
  transition: opacity 0.3s var(--vkui--animation_easing_platform);
  padding: 4px 4px 4px 0;
}

.View--ios .View__panel--prev .PanelHeader__before,
.View--ios .View__panel--prev .PanelHeader__after,
.View--ios .View__panel--prev .PanelHeader__content {
  opacity: 0;
}

/**
 * Android
 */
.PanelHeader--android .PanelHeader__before:not(:empty) {
  padding: 4px 0 4px 4px;
}

.PanelHeader--android .PanelHeader__content {
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
}

.PanelHeader--android .PanelHeader__content-in {
  font-size: 23px;
}

.PanelHeader--android .PanelHeader__content > * {
  padding: 0 12px;
}

.PanelHeader--android .Search,
.PanelHeader--vkcom .Search {
  padding: 0 4px;
}

.SplitCol--spaced
  .PanelHeader--android.PanelHeader--no-before:not(.ModalPageHeader__in)
  .PanelHeader__content {
  padding-left: 0;
}

.SplitCol--viewWidth-tabletPlus.SplitCol--spaced-none.SplitCol--spaced-auto
  .PanelHeader--android.PanelHeader--no-before:not(.ModalPageHeader__in)
  .PanelHeader__content {
  padding-left: 0;
}

@media (--viewWidth-smallTabletPlus) {
  .SplitCol--viewWidth-none.SplitCol--spaced-none.SplitCol--spaced-auto
    .PanelHeader--android.PanelHeader--no-before:not(.ModalPageHeader__in)
    .PanelHeader__content {
    padding-left: 0;
  }
}

.PanelHeader--android.PanelHeader--no-after .PanelHeader__content > *,
.PanelHeader--vkcom.PanelHeader--no-after .PanelHeader__content > * {
  padding-right: 0;
}

.PanelHeader--android.PanelHeader--no-after .PanelHeader__content {
  padding-right: 16px;
}

.PanelHeader--android.PanelHeader--no-before .PanelHeader__content {
  padding-left: 16px;
}

.SplitCol--spaced
  .PanelHeader--android.PanelHeader--no-after:not(.ModalPageHeader__in)
  .PanelHeader__content {
  padding-right: 0;
}

.SplitCol--viewWidth-tabletPlus.SplitCol--spaced-none.SplitCol--spaced-auto
  .PanelHeader--android.PanelHeader--no-after:not(.ModalPageHeader__in)
  .PanelHeader__content {
  padding-right: 0;
}

@media (--viewWidth-smallTabletPlus) {
  .SplitCol--viewWidth-none.SplitCol--spaced-none.SplitCol--spaced-auto
    .PanelHeader--android.PanelHeader--no-after:not(.ModalPageHeader__in)
    .PanelHeader__content {
    padding-right: 0;
  }
}

.PanelHeader--android .PanelHeader__after:not(:empty),
.PanelHeader--vkcom .PanelHeader__after:not(:empty) {
  padding: 4px 4px 4px 0;
}

/**
 * VKCOM
 */
.PanelHeader--vkcom {
  position: relative;
  z-index: var(--vkui_internal--z_index_panel_header_fixed);
}

.PanelHeader--vkcom.PanelHeader--sizeX-regular:not(.ModalPageHeader__in):not(.PanelHeader--sep)
  .PanelHeader__in::after {
  position: absolute;
  left: var(--vkui_internal--thin_border);
  right: var(--vkui_internal--thin_border);
  bottom: 0;
  height: var(--vkui_internal--thin_border);
  background-color: var(--vkui--color_background_content);
  content: '';
}

@media (--sizeX-regular) {
  .PanelHeader--vkcom.PanelHeader--sizeX-none:not(.ModalPageHeader__in):not(.PanelHeader--sep)
    .PanelHeader__in::after {
    position: absolute;
    left: var(--vkui_internal--thin_border);
    right: var(--vkui_internal--thin_border);
    bottom: 0;
    height: var(--vkui_internal--thin_border);
    background-color: var(--vkui--color_background_content);
    content: '';
  }
}

.PanelHeader--vkcom.PanelHeader--sizeX-regular:not(.ModalPageHeader__in) .PanelHeader__in {
  border-top-left-radius: var(--vkui--size_border_radius_paper--regular);
  border-top-right-radius: var(--vkui--size_border_radius_paper--regular);
  box-shadow: 0 0 0 var(--vkui_internal--thin_border) var(--vkui--color_field_border_alpha) inset;
  border-bottom: none;
}

@media (--sizeX-regular) {
  .PanelHeader--vkcom.PanelHeader--sizeX-none:not(.ModalPageHeader__in) .PanelHeader__in {
    border-top-left-radius: var(--vkui--size_border_radius_paper--regular);
    border-top-right-radius: var(--vkui--size_border_radius_paper--regular);
    box-shadow: 0 0 0 var(--vkui_internal--thin_border) var(--vkui--color_field_border_alpha) inset;
    border-bottom: none;
  }
}

.PanelHeader--vkcom .PanelHeader__content {
  text-align: center;
}

.PanelHeader--vkcom .PanelHeader__before:not(:empty) {
  padding: 0 0 0 4px;
}

.PanelHeader--vkcom .PanelHeader__before,
.PanelHeader--vkcom .PanelHeader__after {
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
}

.PanelHeader__separator {
  margin-top: calc(-1 * var(--vkui_internal--thin_border));
}

.PanelHeader--no-before .PanelHeader__content > * {
  padding-left: 0;
}

.PanelHeader--sep {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
