.Header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0;
  padding-left: var(--vkui--size_base_padding_horizontal--regular);
  padding-right: var(--vkui--size_base_padding_horizontal--regular);
  user-select: text;
  font-family: var(--vkui--font_family_base);
}

.Header__main {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--vkui--color_text_primary);
}

.Header__content {
  display: flex;
  align-items: baseline;
}

.Header__content-in {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Header__content-in--multiline {
  white-space: initial;
  word-break: break-word;
}

.Header--multiline {
  white-space: normal;
}

.Header__subtitle {
  color: var(--vkui--color_text_secondary);
}

.Header--mode-primary .Header__content:not(:last-child) {
  margin-top: -1px;
}

.Header--mode-secondary:not(.Header--pi) .Header__main,
.Header--mode-tertiary .Header__main {
  color: var(--vkui--color_text_secondary);
}

.Header__indicator {
  color: var(--vkui--color_text_secondary);
  margin-left: 6px;
  flex-shrink: 0;
}

.Header--mode-primary .Header__indicator {
  color: var(--vkui--color_text_subhead);
}

.Header:not(.Header--pi) .Header__indicator {
  align-self: center;
}

.Header--mode-secondary .Header__indicator {
  margin-left: 8px;
}

.Header__aside {
  white-space: nowrap;
  margin-left: 12px;
}

.Header__aside > :global(.vkuiIcon) {
  position: relative;
  color: var(--vkui--color_icon_secondary);
}

.Header__aside > :global(.vkuiIcon--24) {
  top: 3px;
}

.Header__aside > :global(.vkuiIcon--16) {
  top: 1px;
}

/**
 * ANDROID
 */
.Header--android .Header__main {
  margin: 15px 0 9px;
}

.Header--android.Header--mode-tertiary .Header__main {
  margin-top: 11px;
}

/**
 * iOS
 */

.Header--ios .Header__main {
  margin: 13px 0 9px;
}

.Header--ios.Header--mode-secondary .Header__main {
  margin: 14px 0 10px;
}

.Header--ios.Header--mode-tertiary .Header__main {
  margin-top: 9px;
}

/**
 * VKCOM
 */
.Header--vkcom {
  height: 64px;
  align-items: center;
}

.Header--vkcom .Header__main {
  color: var(--vkui--color_text_primary);
}

.Header--vkcom.Header--mode-primary .Header__content:not(:last-child) {
  margin-top: 0;
}

.Header--vkcom.Header--mode-secondary {
  height: 56px;
}

.Header--vkcom.Header--mode-tertiary {
  height: 40px;
}

/**
 * .Group
 *
 * TODO: Переделать отрицательные отступы https://github.com/VKCOM/VKUI/issues/3508
 */
.Group--mode-plain:not(:first-of-type) .Group__inner > .Header:first-child,
.Group--mode-plain:not(:first-of-type) .Group__inner > .Tappable:first-child > .Header {
  margin-top: -16px;
}

@media (--sizeX-compact) {
  .Group--mode-none:not(:first-of-type) .Group__inner > .Header:first-child,
  .Group--mode-none:not(:first-of-type) .Group__inner > .Tappable:first-child > .Header {
    margin-top: -16px;
  }
}

.Group--mode-card > .Group__inner > .Header:not(.Header--mode-tertiary):first-child,
.Group--mode-card > .Group__inner > .Tappable:first-child > .Header:not(.Header--mode-tertiary) {
  margin-top: -4px;
}

@media (--sizeX-regular) {
  .Group--mode-none > .Group__inner > .Header:not(.Header--mode-tertiary):first-child,
  .Group--mode-none > .Group__inner > .Tappable:first-child > .Header:not(.Header--mode-tertiary) {
    margin-top: -4px;
  }
}
