.Counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--vkui--color_icon_tertiary);
  color: var(--vkui--color_text_contrast);
}

.Counter__in {
  display: block;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  box-sizing: border-box;
}

.Counter--mode-primary {
  background: var(--vkui--color_background_accent);
  color: var(--vkui--color_text_contrast);
}

.Counter--mode-secondary {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}

.Counter--mode-prominent {
  background: var(--vkui--color_accent_red);
  color: var(--vkui--color_text_contrast);
}

.Counter--mode-contrast {
  background: var(--vkui--color_background_content);
  color: var(--vkui--color_text_accent_themed);
}

.Counter--size-m {
  height: 24px;
  min-width: 24px;
  border-radius: 12px;
}

.Counter--size-s {
  height: 18px;
  min-width: 18px;
  border-radius: 9px;
}

/**
 * CMP:
 * Button
 */
.Button--mode-primary.Button--appearance-accent .Counter--mode-inherit {
  background-color: var(--vkui--color_background_content);
  color: var(--vkui--color_text_accent_themed);
}

.Button--mode-secondary.Button--appearance-accent .Counter--mode-inherit,
.Button--mode-tertiary.Button--appearance-accent .Counter--mode-inherit,
.Button--mode-outline.Button--appearance-accent .Counter--mode-inherit {
  background-color: var(--vkui--color_background_accent_themed);
  color: var(--vkui--color_text_contrast_themed);
}

.Button--mode-primary.Button--appearance-positive .Counter--mode-inherit {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_positive);
}

.Button--mode-secondary.Button--appearance-positive .Counter--mode-inherit,
.Button--mode-tertiary.Button--appearance-positive .Counter--mode-inherit,
.Button--mode-outline.Button--appearance-positive .Counter--mode-inherit {
  background-color: var(--vkui--color_background_positive);
  color: var(--vkui--color_text_contrast);
}

.Button--mode-primary.Button--appearance-negative .Counter--mode-inherit {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_negative);
}

.Button--mode-secondary.Button--appearance-negative .Counter--mode-inherit,
.Button--mode-tertiary.Button--appearance-negative .Counter--mode-inherit,
.Button--mode-outline.Button--appearance-negative .Counter--mode-inherit {
  background-color: var(--vkui--color_background_negative);
  color: var(--vkui--color_text_contrast);
}

.Button--mode-primary.Button--appearance-neutral .Counter--mode-inherit {
  background-color: var(--vkui--color_background_content);
  color: var(--vkui--color_text_primary_invariably);
}

.Button--mode-secondary.Button--appearance-neutral .Counter--mode-inherit,
.Button--mode-tertiary.Button--appearance-neutral .Counter--mode-inherit,
.Button--mode-outline.Button--appearance-neutral .Counter--mode-inherit {
  background-color: var(--vkui--color_background_accent);
  color: var(--vkui--color_text_contrast);
}

.Button--mode-primary.Button--appearance-overlay .Counter--mode-inherit {
  background-color: var(--vkui--color_icon_primary_invariably);
  color: var(--vkui--color_text_contrast);
}

.Button--mode-secondary.Button--appearance-overlay .Counter--mode-inherit,
.Button--mode-tertiary.Button--appearance-overlay .Counter--mode-inherit,
.Button--mode-outline.Button--appearance-overlay .Counter--mode-inherit {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_primary_invariably);
}

/**
 * CMP:
 * WriteBarIcon
 */
.WriteBarIcon .Counter--mode-inherit {
  color: var(--vkui--color_text_contrast_themed);
  background: var(--vkui--color_background_positive);
}

/**
 * CMP:
 * SubnavigationButton
 */
.SubnavigationButton .Counter--mode-inherit {
  background: var(--vkui--color_background_accent_themed);
  color: var(--vkui--color_text_contrast_themed);
}

.SubnavigationButton--selected:not(.SubnavigationButton--mode-tertiary) .Counter--mode-inherit {
  background-color: var(--vkui--color_background_content);
  color: var(--vkui--color_text_accent_themed);
}
